var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "crop-capture-modal--package-capture",
        "ok-variant": "success",
        size: "lg",
        "ok-title": _vm.FormMSG(29801, "Save"),
        "cancel-title": _vm.FormMSG(212302, "Cancel"),
        title: _vm.FormMSG(26700, "Picture capture"),
        centered: "",
      },
      on: {
        ok: _vm.handleSaveCrop,
        cancel: _vm.handleCloseModal,
        hidden: _vm.handleCloseModal,
      },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _c(
            "div",
            { staticClass: "image_crop_container_wrapper" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("vue-cropper", {
                        key: _vm.refCrop,
                        ref: "croppieRef",
                        staticClass: "cropper-container",
                        attrs: {
                          img: _vm.options.img,
                          "output-size": _vm.options.size,
                          "output-type": _vm.options.outputType,
                          info: _vm.options.info,
                          full: _vm.options.full,
                          fixed: _vm.options.fixed,
                          "fixed-number": _vm.options.fixedNumber,
                          "can-move": _vm.options.canMove,
                          "can-move-box": _vm.options.canMoveBox,
                          "fixed-box": _vm.options.fixedBox,
                          original: _vm.options.original,
                          "auto-crop": _vm.options.autoCrop,
                          "auto-crop-width": _vm.options.autoCropWidth,
                          "auto-crop-height": _vm.options.autoCropHeight,
                          "center-box": _vm.options.centerBox,
                          height: _vm.options.height,
                        },
                        on: { "real-time": _vm.handleRealTime },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "previewer-area", attrs: { xl: "3" } },
                    [
                      _c(
                        "figure",
                        {
                          staticClass: "figure cam_capture-figure",
                          class: _vm.previews.div,
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.previews.url,
                              alt: "no picture cropped yet",
                            },
                          }),
                          _c(
                            "figcaption",
                            { staticClass: "figure-caption text-center" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "Cropped picture")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "outline-secondary",
                                block: "",
                                size: "md",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.rotate("left")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "icon-reload" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.FormMSG(4, "Rotate picture")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm.hasValidationButton
                            ? _c(
                                "b-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled:
                                      _vm.$nil(_vm.previews) ||
                                      _vm.previews.url === "",
                                  },
                                  on: { click: _vm.handleConfirmCrop },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(2, "Confirme Crop")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("loading", {
            attrs: { "is-full-page": "", active: _vm.isLoading },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }