export default {
	name: 'ModalMixin',
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isModalOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return this.$emit('input', val);
			}
		}
	}
};
